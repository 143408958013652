@import url("https://fonts.googleapis.com/css?family=Cairo:300,300i,400,400i,500,500i,700,700i,900,900i");

body {
  margin: 0;
 
}

html {
  scroll-behavior: smooth;
  background-color: #f6f8f9;
}

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: "Cairo", sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.button {
  border: none !important;
  background-color: #ffffff !important;
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .custom {
    left: 100px !important;
  }
}

